// FontAwesome icons

// Use plugins to only use the features we need
// Old import: import { config, dom, library } from 'Vendor/@fortawesome/fontawesome-svg-core'
// Docs: https://fontawesome.com/docs/apis/javascript/plugins
import {
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	register,
	ReplaceElements,
} from 'Vendor/@fortawesome/fontawesome-svg-core/plugins.mjs';

const api = register([
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	ReplaceElements,
])

api.config.autoReplaceSvg = 'nest';
api.config.observeMutations = true;

// Solid icons
// Core
import { faAngleLeft         } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight        } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleRight';
import { faCaretDown         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp           } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCheck             } from 'Vendor/@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle            } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleArrowUp     } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleArrowUp';
import { faCircleMinus       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleMinus';
import { faCirclePlus        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faCircleXmark       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faMagnifyingGlass   } from 'Vendor/@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faMinus             } from 'Vendor/@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus              } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlus';
import { faQuoteLeft         } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faStar              } from 'Vendor/@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalfStroke    } from 'Vendor/@fortawesome/free-solid-svg-icons/faStarHalfStroke';
import { faXmark             } from 'Vendor/@fortawesome/free-solid-svg-icons/faXmark';

// Account icons
import { faAsterisk          } from 'Vendor/@fortawesome/free-solid-svg-icons/faAsterisk';
import { faEnvelope          } from 'Vendor/@fortawesome/free-solid-svg-icons/faEnvelope';
import { faGear              } from 'Vendor/@fortawesome/free-solid-svg-icons/faGear';
import { faHeart             } from 'Vendor/@fortawesome/free-solid-svg-icons/faHeart';
import { faUser              } from 'Vendor/@fortawesome/free-solid-svg-icons/faUser';
import { faUserGear          } from 'Vendor/@fortawesome/free-solid-svg-icons/faUserGear';

// Charity categories
import { faChild             } from 'Vendor/@fortawesome/free-solid-svg-icons/faChild';
import { faEarthAfrica       } from 'Vendor/@fortawesome/free-solid-svg-icons/faEarthAfrica';
import { faFutbol            } from 'Vendor/@fortawesome/free-solid-svg-icons/faFutbol';
import { faGraduationCap     } from 'Vendor/@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faHandshakeAngle    } from 'Vendor/@fortawesome/free-solid-svg-icons/faHandshakeAngle';
import { faHelicopter        } from 'Vendor/@fortawesome/free-solid-svg-icons/faHelicopter';
import { faIdCard            } from 'Vendor/@fortawesome/free-solid-svg-icons/faIdCard';
import { faLeaf              } from 'Vendor/@fortawesome/free-solid-svg-icons/faLeaf';
import { faList              } from 'Vendor/@fortawesome/free-solid-svg-icons/faList';
import { faPalette           } from 'Vendor/@fortawesome/free-solid-svg-icons/faPalette';
import { faPaw               } from 'Vendor/@fortawesome/free-solid-svg-icons/faPaw';
import { faPlaceOfWorship    } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlaceOfWorship';
import { faStethoscope       } from 'Vendor/@fortawesome/free-solid-svg-icons/faStethoscope';
import { faUsers             } from 'Vendor/@fortawesome/free-solid-svg-icons/faUsers';
import { faWheelchair        } from 'Vendor/@fortawesome/free-solid-svg-icons/faWheelchair';

// Navigation
import { faBars              } from 'Vendor/@fortawesome/free-solid-svg-icons/faBars';
import { faCircleInfo        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faHouse             } from 'Vendor/@fortawesome/free-solid-svg-icons/faHouse';
import { faPencil            } from 'Vendor/@fortawesome/free-solid-svg-icons/faPencil';
import { faRightFromBracket  } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faRightToBracket    } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightToBracket';

// Other
import { faBarcode           } from 'Vendor/@fortawesome/free-solid-svg-icons/faBarcode';
import { faBasketShopping    } from 'Vendor/@fortawesome/free-solid-svg-icons/faBasketShopping';
import { faBriefcase         } from 'Vendor/@fortawesome/free-solid-svg-icons/faBriefcase';
import { faBullhorn          } from 'Vendor/@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCircleExclamation } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCloudArrowUp      } from 'Vendor/@fortawesome/free-solid-svg-icons/faCloudArrowUp';
import { faCopy              } from 'Vendor/@fortawesome/free-solid-svg-icons/faCopy';
import { faCreditCard        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCreditCard';
import { faGift              } from 'Vendor/@fortawesome/free-solid-svg-icons/faGift';
import { faMugSaucer         } from 'Vendor/@fortawesome/free-solid-svg-icons/faMugSaucer';
import { faPiggyBank         } from 'Vendor/@fortawesome/free-solid-svg-icons/faPiggyBank';
import { faRotate            } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotate';
import { faSliders           } from 'Vendor/@fortawesome/free-solid-svg-icons/faSliders';
import { faSpinner           } from 'Vendor/@fortawesome/free-solid-svg-icons/faSpinner';
import { faTrophy            } from 'Vendor/@fortawesome/free-solid-svg-icons/faTrophy';
import { faUpRightFromSquare } from 'Vendor/@fortawesome/free-solid-svg-icons/faUpRightFromSquare';

api.library.add({
	// Core
	faAngleLeft,
	faAngleRight,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCircle,
	faCircleArrowUp,
	faCircleMinus,
	faCirclePlus,
	faCircleXmark,
	faMagnifyingGlass,
	faMinus,
	faPlus,
	faQuoteLeft,
	faStar,
	faStarHalfStroke,
	faXmark,

	// Account icons
	faAsterisk,
	faEnvelope,
	faGear,
	faHeart,
	faUser,
	faUserGear,
	
	// Charity categories
	faChild,
	faEarthAfrica,
	faFutbol,
	faGraduationCap,
	faHandshakeAngle,
	faHelicopter,
	faIdCard,
	faLeaf,
	faList,
	faPalette,
	faPaw,
	faPlaceOfWorship,
	faStethoscope,
	faUsers,
	faWheelchair,

	// Navigation
	faBars,
	faCircleInfo,
	faHouse,
	faPencil,
	faRightFromBracket,
	faRightToBracket,

	// Other
	faBarcode,
	faBasketShopping,
	faBriefcase,
	faBullhorn,
	faCircleExclamation,
	faCloudArrowUp,
	faCopy,
	faCreditCard,
	faGift,
	faMugSaucer,
	faPiggyBank,
	faRotate,
	faSliders,
	faSpinner,
	faTrophy,
	faUpRightFromSquare,
});

// Brand icons
import { faApple             } from 'Vendor/@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook          } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF         } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookMessenger } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faInstagram         } from 'Vendor/@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn        } from 'Vendor/@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter           } from 'Vendor/@fortawesome/free-brands-svg-icons/faTwitter';

api.library.add({
	faApple,
	faFacebook,
	faFacebookF,
	faFacebookMessenger,
	faInstagram,
	faLinkedinIn,
	faTwitter,
});

// Replace and watch
api.dom.i2svg();
api.dom.watch();